<template>

  <div class="mt-4 editions-wrap">

    <b-tabs>

      <b-tab title="Total sales" active>
        <div class="mx-sm-5">
          <table v-if="mostEth" class="table table-borderless">
            <thead>
            <tr>
              <th></th>
              <th></th>
              <th class="d-none d-sm-table-cell text-right">Editions</th>
              <th class="d-none d-sm-table-cell text-right">Sold</th>
              <!--              <th class="d-none d-sm-table-cell text-right">Avg. price ETH</th>-->
              <th class="text-right">Total</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="artist in mostEth" :key="artist.address">
              <td class="text-left align-middle">
                <router-link :to="{ name: 'artist', params: { artistAccount: artist.address } }">
                  <artist-image :logo="findArtistsForAddress(artist.address).logo" style="height: 50px; width: 50px"
                                v-if="findArtistsForAddress(artist.address)"></artist-image>
                </router-link>
              </td>
              <td class="text-left align-middle">
                <router-link :to="{ name: 'artist', params: { artistAccount: artist.address } }">
                  <span
                    v-if="findArtistsForAddress(artist.address)">{{ findArtistsForAddress(artist.address).name }}</span>
                  <span v-else>artist.address</span>
                </router-link>
              </td>
              <td class="d-none d-sm-table-cell align-middle text-right">{{ artist.editionsCount }}</td>
              <td class="d-none d-sm-table-cell align-middle text-right">{{ artist.salesCount }}</td>
              <!--            <td class="d-none d-sm-table-cell align-middle text-right">{{ (parseFloat(artist.totalValueInEth) / parseInt(artist.salesCount)).toFixed(4) }}</td>-->
              <td class="align-middle text-right">
                <price-in-eth :value="artist.totalValueInEth | to4Dp"></price-in-eth>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </b-tab>

    </b-tabs>

  </div>

</template>

<script>

  import {mapGetters, mapState} from 'vuex';
  import {ARTIST_LEADERBOARDS} from '../gql/queries';
  import ArtistImage from '../components/artist/ArtistImage';
  import PriceInEth from "../components/generic/PriceInEth";
  import {BTabs, BTab} from 'bootstrap-vue';

  export default {
    name: 'boards',
    components: {
      PriceInEth, 
      ArtistImage,
      ...{BTabs, BTab}
    },
    methods: {},
    computed: {
      ...mapState([]),
      ...mapGetters([
        'findArtistsForAddress'
      ]),
    },
    apollo: {
      mostEth: ARTIST_LEADERBOARDS,
    },
  };
</script>

<style scoped lang="scss">

</style>
