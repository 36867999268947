<template>
  <div class='container'>
    <vue-markdown-plus :source="post">
    </vue-markdown-plus>
  </div>
</template>
<script>
  import VueMarkdownPlus from 'vue-markdown-plus';
  export default {
    name: 'blog',
    components: {
      VueMarkdownPlus
    },
    data() {
      return {
        post: ''
      }
    },
    methods: {
      async fetchPost(name) {
        const result = (await (await fetch(`/static/blog/${name}.md`)).text())
        return result
      }
    },
    computed: {
    },
    created() {
      this.fetchPost(this.$route.name).then(result => {
        this.post = result
      })
    },
  };
</script>
<style scoped lang="scss">
  @import '../ko-colours.scss';
  @import '../ko-card.scss';
</style>
